<template>
    <b-container class="py-5 text-left cookies-policy">
        <h1 class="mb-5">Política de Cookies</h1>
        <p>
            La sociedad Delivering Geniality, S.A.P.I. de C.V. (“<span class="definition"
                >Zubut</span
            >”) utiliza cookies y tecnologías similares para ayudar a proporcionar, proteger y
            mejorar la plataforma https://www.zubut.com y los sitios web o subdominios comprendidos
            por Zubut (el “<span class="definition">Sitio Web</span>” o la “<span class="definition"
                >Plataforma</span
            >”) según los términos y condiciones de Zubut establecidos en el Sitio Web (los “<span
                class="definition"
                >Términos</span
            >”). La presente política explica cómo y por qué Zubut utiliza estas tecnologías y las
            opciones de las que usted como usuario de la Plataforma dispone (el “<span
                class="definition"
                >Usuario</span
            >”).
        </p>
        <p>
            Una cookie es un pequeño archivo de datos que se instala en su dispositivo (“<span
                class="definition"
                >Cookie</span
            >”) por ejemplo, una Cookie podría permitir a Zubut reconocer el navegador del Usuario y
            otra podría almacenar las preferencias del Usuario. En el Sitio Web se usan dos tipos de
            Cookies, en primer lugar, se encuentran las “cookies de sesión” y, en segundo lugar, las
            “cookies permanentes”. Las Cookies de sesión, normalmente, caducan cuando el Usuario
            cierra su navegador, mientras que las cookies permanentes seguirán en el dispositivo del
            Usuario después de que el Usuario haya cerrado el navegador y podrán ser utilizadas de
            nuevo cuando el Usuario vuelva a acceder a la Plataforma.
        </p>
        <p>
            Además, Zubut utiliza otras tecnologías que presentan una funcionalidad similar a la de
            las Cookies, como balizas web (“<span class="definition">Web Beacons</span>”), píxeles,
            identificadores de móviles y direcciones URL de seguimiento, para obtener datos
            personales de registro que están y serán tratados por Zubut conforme al aviso de
            privacidad de la Plataforma. Por ejemplo, los mensajes de correo electrónico podrán
            contener balizas web y direcciones URL de seguimiento para determinar si el Usuario ha
            abierto determinados mensajes o accedido a determinados enlaces.
        </p>

        <h2 class="mt-5 mb-4">Definiciones.</h2>
        <p>
            En caso de que haya algún término cuya definición no se incluya en la presente Política
            de Cookies (como “Anfitriones”), significa que dicho término debe interpretarse de
            conformidad con los Términos de Zubut descritos en la Plataforma.
        </p>
        <h2 class="mt-5 mb-4">Fines del uso de estas tecnologías.</h2>
        <p>
            La Plataforma de Zubut utiliza estas tecnologías con varios fines, tales como los
            siguientes:
        </p>
        <ul>
            <li>Permitir al Usuario para acceder y utilizar el Sitio Web.</li>
            <li>
                Permitir, facilitar y optimizar el funcionamiento del Sitio Web y su acceso a la
                misma.
            </li>
            <li>
                Comprender mejor de qué forma navega el Usuario por el Sitio Web e interactúa con
                ésta y para mejorarla.
            </li>
            <li>
                Proporcionar al Usuario publicidad a medida.
            </li>
            <li>
                Mostrar al Usuario contenidos que sean más acertados.
            </li>
            <li>
                Supervisar y analizar el rendimiento, el funcionamiento y la eficacia de los
                anuncios subidos por los anfitriones de espacios a la Plataforma.
            </li>
            <li>
                Velar por el cumplimiento de los contratos que rigen el uso del Sitio Web.
            </li>
            <li>
                Detectar y prevenir actividades fraudulentas, así como realizar investigaciones con
                confianza y seguridad para la detección de eventuales fraudes derivados del uso de
                la Plataforma de Zubut.
            </li>
            <li>
                Fines relacionados con el servicio de atención al cliente de Zubut y en el marco de
                actividades de análisis, investigación, desarrollo de productos y cumplimiento
                normativo de Zubut.
            </li>
        </ul>
        <h2 class="mt-5 mb-4">Cookies.</h2>
        <p>
            Cuando el Usuario visita el Sitio Web, se colocan Cookies en el ordenador del Cliente.
            De igual forma las Cookies se envían al ordenador u otro dispositivo del Usuario
            conectado a internet para identificar de forma exclusiva el navegador o para almacenar
            información o ajustes en el navegador. Las Cookies permiten a Zubut reconocer al usuario
            cuando regresa, situación que permite proporcionar una experiencia personalizada, así
            como permite detectar ciertos tipos de fraude.
        </p>
        <p>
            En muchos casos, el Usuario puede administrar las preferencias de Cookies y optar por no
            tener Cookies y otras tecnologías de recopilación de datos que se utilicen ajustando la
            configuración del navegador. Todos los navegadores son diferentes, así que visite la
            sección "Ayuda" del navegador cuando desee aprender sobre las preferencias de Cookies y
            otras configuraciones de privacidad que puedan estar disponibles. Tenga en cuenta que,
            si el Usuario opta por eliminar o rechazar las Cookies o el almacenamiento local, esto
            podría afectar a las características, la disponibilidad, la funcionalidad del Sitio Web
            y en general la experiencia al usar la Plataforma o los servicios de Zubut.
        </p>
        <h2 class="mt-5 mb-4">Cookies de Flash.</h2>
        <p>
            Zubut podrá usar Cookies de Flash, también conocidas como objetos almacenados locales, y
            tecnologías similares para personalizar y mejorar la experiencia en línea del Usuario.
            Una Cookie Flash es un pequeño archivo de datos colocado en un ordenador mediante la
            tecnología Adobe Flash. Adobe Flash Player es una aplicación que permite un rápido
            desarrollo de contenido dinámico, como clips de vídeo y animaciones.
        </p>
        <p>
            Zubut podrá usar Cookies de Flash para personalizar y mejorar su experiencia en línea y
            para entregar contenido para reproductores Flash. También Zubut podrá usar cookies de
            Flash por motivos de seguridad, para recopilar determinadas métricas del Sitio Web y
            para ayudar a recordar los ajustes y las preferencias. Las Cookies de Flash se gestionan
            a través de una interfaz diferente a la proporcionada por el navegador web. Para
            administrar las Cookies de Flash, visite el sitio web de Adobe.
        </p>
        <p>
            Si deshabilita las Cookies de Flash u otras tecnologías similares, el Usuario deberá
            tener en cuenta que es posible que no tenga acceso a ciertos contenidos y
            características del producto, como el dispositivo que recuerda un anuncio que ha visto
            durante una visita anterior.
        </p>
        <h2 class="mt-5 mb-4">Etiquetas de píxel y Web Beacons.</h2>
        <p>
            Las etiquetas de píxel y las Web Beacons son pequeñas imágenes gráficas y pequeños
            bloques de código que Zubut podrá colocar en el Sitio Web, anuncios o en nuestros
            correos electrónicos que permiten a Zubut determinar si ha realizado una acción
            específica. Cuando el Usuario accede a estas páginas, o cuando abre un correo
            electrónico, las etiquetas de píxel y las Web Beacons permiten saber que ha accedido al
            Sitio Web o que el Usuario ha abierto el correo electrónico. Estas herramientas ayudan a
            medir la respuesta a las comunicaciones y mejorar el Sitio Web y promociones.
        </p>
        <h2 class="mt-5 mb-4">Registros del servidor y otras tecnologías.</h2>
        <p>
            Zubut podrá recopilar distintos tipos diferentes de información de los registros del
            servidor y otras tecnologías, por ejemplo, información sobre el dispositivo que utiliza
            para acceder a la Plataforma, su tipo de sistema operativo, tipo de navegador, dominio y
            otros ajustes del sistema, así como el idioma que utiliza su sistema, el país y la zona
            horaria donde se encuentra el dispositivo. Los registros de servidor de Zubut también
            registran la dirección IP del dispositivo que utiliza para conectarse a internet. Una
            dirección IP es un identificador único que los dispositivos requieren para identificarse
            y comunicarse entre sí en internet. También Zubut puede recopilar información sobre el
            Sitio Web que estaba visitando antes de acceder a la Plataforma de Zubut y el Sitio Web
            que visita después de salir del Sitio Web de Zubut. Estas herramientas ayudan a mejorar
            la experiencia del Usuario y ofrecer los servicios de Zubut.
        </p>
        <h2 class="mt-5 mb-4">Información del dispositivo.</h2>
        <p>
            Zubut podrá utilizar información relacionada con el dispositivo para autenticar a los
            usuarios, por ejemplo, Zubut podrá usar la dirección IP, información del navegador u
            otros datos proporcionados por el navegador o dispositivo para identificar el
            dispositivo que se utiliza para acceder a la Plataforma. También Zubut podrá usar estas
            técnicas relacionadas con el dispositivo para asociarlo con diferentes dispositivos que
            puede utilizar para acceder al contenido del Sitio Web, incluso para propósitos de
            protección contra fraudes y para orientar mejor la publicidad.
        </p>
        <h2 class="mt-5 mb-4">Terceros.</h2>
        <p>
            Zubut tendrá la posibilidad de permitir a determinados socios comerciales colocar estas
            tecnologías en el Sitio Web, tales socios utilizan estas tecnologías 1) para ayudar a
            analizar de qué modo es utilizada la Plataforma, por ejemplo mediante la anotación de
            los servicios de terceros desde los que el Usuario llegó, 2) para vender o anunciar al
            Usuario servicios de Zubut en la Plataforma y sitios web de terceros, 3) para ayudar a
            detectar o prevenir actividades fraudulentas o realizar evaluaciones de riesgos, o 4)
            para recabar información acerca de las actividades que son realizadas en el Sitio Web,
            otros sitios web o los anuncios en los que el Usuario haya hecho clic. Por ejemplo, para
            ayudar a comprender mejor de qué forma utiliza la gente el Sitio Web, Zubut trabaja con
            varios socios de análisis, incluido Google Analytics, por lo que para evitar que Google
            Analytics utilice la información del Usuario con fines de análisis, el Usuario podrá
            instalar el complemento de inhabilitación para navegadores de Google Analytics
            <a href="https://tools.google.com/dlpage/gaoptout">haciendo clic aquí</a>.
        </p>
        <p>
            Tales tecnologías de seguimiento también podrán ser utilizadas por terceros para colocar
            anuncios que en opinión de éstos tengan más probabilidades de captar su atención y para
            medir la eficacia de sus anuncios tanto en la Plataforma de Zubut como en otros sitios
            web y servicios en línea. Las cookies de segmentación y de publicidad que se utilizan
            podrán incluir Google y otras redes y servicios de publicidad de los que se hagan uso.
        </p>
        <h2 class="mt-5 mb-4">Complementos sociales de terceros.</h2>
        <p>
            El Sitio Web podrá utilizar complementos sociales (“<span class="definition"
                >Social Plug-ins</span
            >”) proporcionados y gestionados por terceros como, por ejemplo, el botón “Me Gusta” de
            Facebook. De este modo, el Usuario podrá enviar al tercero la información que esté
            visualizando en determinada parte de la Plataforma. Si el Usuario no ha accedido a la
            cuenta que tiene con el tercero, es posible que éste no conozca la identidad del
            Usuario. Si el Usuario ha accedido a la cuenta que tiene con el tercero, este
            posiblemente sea capaz de asociar con dicha cuenta información o acciones relativas a
            las interacciones del Usuario con la Plataforma de Zubut. Favor de consultar las
            políticas de privacidad del tercero para obtener más información sobre las prácticas de
            este en materia de datos.
        </p>
        <h2 class="mt-5 mb-4">Preferencias de cookies.</h2>
        <p>
            La mayoría de los navegadores aceptan cookies de forma automática, aunque el Usuario
            puede modificar la configuración de su navegador para rechazarlas, accediendo a la
            sección de Ayuda de la barra de herramientas de éstos.
        </p>
        <p>
            Las Cookies de Flash funcionan de forma diferente a las cookies de navegación y no
            pueden ser eliminadas con las herramientas de gestión de Cookies existentes en los
            navegadores web. Para obtener más información acerca de cómo gestionar las Cookies de
            Flash, puede visitar el <a href="https://www.adobe.com/">sitio web de Adobe</a> y
            realizar los cambios oportunos.
        </p>
        <p>
            Es posible que el dispositivo móvil permita controlar cookies a través de su función de
            ajustes. Consulte las instrucciones del fabricante del dispositivo para obtener más
            información.
        </p>
        <p>
            Si opta por rechazar cookies, es posible que algunas secciones de la Plataforma no
            funcionen de la manera prevista o no funcionen en absoluto.
        </p>
        <p>
            Las presentes Políticas de Cookies se modificaron por última ocasión el 29 de julio de
            2021.
        </p>
    </b-container>
</template>

<script>
export default {
    name: "CookiesPolicy",
    metaInfo: {
        title: "Política de Cookies",
        meta: [
            {
                name: "description",
                content: `Política de Cookies de Zubut`
            }
        ]
    }
};
</script>

<style scoped lang="scss">
.cookies-policy {
    .definition {
        text-decoration: underline;
        font-weight: bold;
        font-style: italic;
    }

    ol {
        list-style-type: lower-alpha;

        li::marker {
            font-weight: bold;
        }
    }
}
</style>
